import { useNavigate } from "react-router-dom";
import Header from "../../components/panel/Header";
import PanelLayout from "../../components/panel/Layout";

const Salary = () => {
  const navigate = useNavigate();
  return (
    <PanelLayout>
      <main className="leave-container">
        <Header title="Salary" />

        <div className="ticket-list">
          {[0, 1, 2, 3, 4, 5, 6].map((el) => {
            return (
              <div className="ticket-card" key={el}>
                <div className="ticket-info">
                  <div className="ticket-details">
                    <h3>Month {el + 1}</h3>
                    <p>Not received confirmation letter after completing 6 months</p>
                  </div>
                </div>
                <div className="ticket-status">
                  <div
                    className="status-badge"
                    onClick={() => navigate(`/panel/salary/${Date.now()}`)}
                  >
                    View
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </main>
    </PanelLayout>
  );
};

export default Salary;
