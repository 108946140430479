import React from "react";
import { Link } from "react-router-dom";

const ProfilePopup = () => {
  return (
    <div className="popup-menu">
      <div className="popup-content">
        <div className="user-info">
          <p className="user-name">Syed Hasnain Mehadi</p>
          <p className="user-email">syed.mehadi@gmail.com</p>
        </div>

        <a href="#profile" className="menu-item">
          Your Profile
        </a>
        <a href="#settings" className="menu-item">
          Settings
        </a>
        <a href="#activity" className="menu-item">
          Activity Log
        </a>

        <div className="menu-divider"></div>
        <Link to="/auth/login" className="menu-item logout">
          Sign out
        </Link>
      </div>
    </div>
  );
};

export default ProfilePopup;
