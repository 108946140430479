import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Salary from "../pages/panel/Salary";
import SalaryDetails from "../pages/panel/SalaryDetails";

const Home = lazy(() => import("../pages/panel/Home"));
const Punch = lazy(() => import("../pages/panel/Punch"));
const Attendance = lazy(() => import("../pages/panel/Attendance"));
const Leave = lazy(() => import("../pages/panel/Leave"));
const LeaveApply = lazy(() => import("../pages/panel/LeaveApply"));
const Page404 = lazy(() => import("../pages/info/NotFound"));

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route
        path="/home"
        element={
          <Suspense fallback={<>Loading...</>}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path="/punch"
        element={
          <Suspense fallback={<>Loading...</>}>
            <Punch />
          </Suspense>
        }
      />

      <Route
        path="/attendance"
        element={
          <Suspense fallback={<>Loading...</>}>
            <Attendance />
          </Suspense>
        }
      />
      <Route
        path="/salary"
        element={
          <Suspense fallback={<>Loading...</>}>
            <Salary />
          </Suspense>
        }
      />
      <Route
        path="/leave"
        element={
          <Suspense fallback={<>Loading...</>}>
            <Leave />
          </Suspense>
        }
      />
      <Route
        path="/leave/:id"
        element={
          <Suspense fallback={<>Loading...</>}>
            <LeaveApply />
          </Suspense>
        }
      />
      <Route
        path="/salary/:id"
        element={
          <Suspense fallback={<>Loading...</>}>
            <SalaryDetails />
          </Suspense>
        }
      />
      <Route
        path="/404"
        element={
          <Suspense fallback={<>Loading...</>}>
            <Page404 />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/panel/404" />} />
    </Routes>
  );
};
export default PrivateRoutes;
