import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Attendance, AttendanceState } from "./types";

const initialState: AttendanceState = {
  records: [],
  loading: false,
  error: null,
};

const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    setRecords: (state, action: PayloadAction<Attendance[]>) => {
      state.records = action.payload;
    },
    addRecord: (state, action: PayloadAction<Attendance>) => {
      state.records.push(action.payload);
    },
    updateRecord: (state, action: PayloadAction<Attendance>) => {
      const index = state.records.findIndex((record) => record.id === action.payload.id);
      if (index !== -1) {
        state.records[index] = action.payload;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setRecords, addRecord, updateRecord, setLoading, setError } =
  attendanceSlice.actions;
export default attendanceSlice.reducer;
