import "./css/content.css";
import "./css/panel.css";
import SideMenu from "./SideMenu";

const PanelLayout = ({ children }: any) => {
  return (
    <div className="panel-container">
      <SideMenu />
      <div className="panel-main-content">{children}</div>
    </div>
  );
};

export default PanelLayout;
