import { useState } from "react";
import { FaUser } from "react-icons/fa6";
import ProfilePopup from "./ProfilePopup";

const Header = ({ title: title }: { title: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <header className="header">
      <h2 className="page-title">{title}</h2>
      <div className="user-menu">
        <div className="user-profile" onClick={() => setIsOpen(!isOpen)}>
          <span>Syed Hasnain Mehadi</span>
          <div className="user-avatar">
            <FaUser size="20px" />
          </div>
          {isOpen && <ProfilePopup />}
        </div>
      </div>
    </header>
  );
};

export default Header;
