import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth, User } from "./types";

export const userApi = {
  loginUser: async (userData: any): Promise<Auth> => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
    });
    if (!response.ok) throw new Error("Failed to create user");
    return response.json();
  },
  fetchUsers: async (): Promise<User[]> => {
    const response = await fetch("/api/users");
    if (!response.ok) throw new Error("Failed to fetch users");
    return response.json();
  },
  fetchUserById: async (id: string): Promise<User> => {
    const response = await fetch(`/api/users/${id}`);
    if (!response.ok) throw new Error("Failed to fetch user");
    return response.json();
  },
  createUser: async (userData: Omit<User, "id">): Promise<User> => {
    const response = await fetch("/api/users", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
    });
    if (!response.ok) throw new Error("Failed to create user");
    return response.json();
  },
};

export const userLogin = createAsyncThunk(
  "user/login",
  async (userData: any, { rejectWithValue }) => {
    try {
      return await userApi.loginUser(userData);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "user/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      return await userApi.fetchUsers();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchUserById = createAsyncThunk(
  "user/fetchUserById",
  async (id: string, { rejectWithValue }) => {
    try {
      return await userApi.fetchUserById(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
