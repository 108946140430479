import { useNavigate } from "react-router-dom";
import Header from "../../components/panel/Header";
import PanelLayout from "../../components/panel/Layout";
import "../../components/panel/css/salary.css";

const SalaryDetails = () => {
  const navigate = useNavigate();
  return (
    <PanelLayout>
      <main className="salary-details-container">
        {/* <Header title="Salary Details" /> */}

        <div className="header">
          <svg className="logo" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="45" fill="none" stroke="#000" stroke-width="2" />
            <path d="M50 5 A45 45 0 0 1 95 50" fill="#4169E1" />
            <path d="M95 50 A45 45 0 0 1 50 95" fill="#32CD32" />
            <path d="M50 95 A45 45 0 0 1 5 50" fill="#FF6347" />
            <path d="M5 50 A45 45 0 0 1 50 5" fill="#FFD700" />
            <circle cx="50" cy="50" r="10" fill="#fff" />
          </svg>
          <h1 className="company-name">Employee Self Service</h1>
          <div className="company-address">Bhubhulaya Double trouble</div>
          <div className="company-address">Mumbai, Maharashtra, IN - 400093</div>
          <div className="payslip-title">Payslip for the month of November, 2024</div>
        </div>

        <div className="employee-details">
          <div className="employee-left">
            <div className="detail-row">
              <div className="detail-label">Name:</div>
              <div className="detail-value">Sachin Tendulkar</div>
            </div>
            <div className="detail-row">
              <div className="detail-label">Designation:</div>
              <div className="detail-value">Co-Founder</div>
            </div>
            <div className="detail-row">
              <div className="detail-label">Department:</div>
              <div className="detail-value">Co-Founder</div>
            </div>
            <div className="detail-row">
              <div className="detail-label">Location:</div>
              <div className="detail-value">Bengaluru</div>
            </div>
            <div className="detail-row">
              <div className="detail-label">LOP:</div>
              <div className="detail-value">0.0</div>
            </div>
          </div>
          <div className="employee-right">
            <div className="detail-row">
              <div className="detail-label">Employee ID:</div>
              <div className="detail-value">BTP/001</div>
            </div>
            <div className="detail-row">
              <div className="detail-label">Bank Name:</div>
              <div className="detail-value">Andhra Bank</div>
            </div>
            <div className="detail-row">
              <div className="detail-label">Bank Account Number:</div>
              <div className="detail-value">23232323</div>
            </div>
            <div className="detail-row">
              <div className="detail-label">PAN:</div>
              <div className="detail-value">HDAF5422H</div>
            </div>
          </div>
        </div>

        <div className="salary-details">
          <div className="salary-header">
            <div className="salary-column">Earnings</div>
            <div className="amount-column">Amount</div>
            <div className="salary-column">Deductions</div>
            <div className="amount-column">Amount</div>
          </div>

          <div className="salary-row">
            <div className="salary-column">Basic</div>
            <div className="amount-column">₹ 83,333.50</div>
            <div className="salary-column">Income Tax</div>
            <div className="amount-column">₹ 5,064.95</div>
          </div>

          <div className="salary-row">
            <div className="salary-column">HRA</div>
            <div className="amount-column">₹ 41,666.75</div>
            <div className="salary-column">Provident Fund</div>
            <div className="amount-column">₹ 1,800.00</div>
          </div>

          <div className="salary-row">
            <div className="salary-column">Telephone Reimbursements</div>
            <div className="amount-column">₹ 0.83</div>
            <div className="salary-column">Professional Tax</div>
            <div className="amount-column">₹ 200.00</div>
          </div>

          <div className="salary-row">
            <div className="salary-column">Bonus</div>
            <div className="amount-column">₹ 0.00</div>
            <div className="salary-column"></div>
            <div className="amount-column"></div>
          </div>

          <div className="salary-row">
            <div className="salary-column">LTA</div>
            <div className="amount-column">₹ 0.00</div>
            <div className="salary-column"></div>
            <div className="amount-column"></div>
          </div>

          <div className="salary-row">
            <div className="salary-column">Special Allowance</div>
            <div className="amount-column">₹ 29,667.00</div>
            <div className="salary-column"></div>
            <div className="amount-column"></div>
          </div>

          <div className="total-row">
            <div className="salary-column">Total Earnings</div>
            <div className="amount-column">₹ 154,668.08</div>
            <div className="salary-column">Total Deductions</div>
            <div className="amount-column">₹ 7,064.95</div>
          </div>
        </div>

        <div className="net-pay">Net Pay for the month: ₹ 145,803.14</div>

        <div className="separator"></div>

        <div className="footer">
          <div>This is a system generated payslip and does not require signature.</div>
          <div>Generated by Asanify</div>
          <div>for more details, log on to asanify.com</div>
        </div>
      </main>
    </PanelLayout>
  );
};

export default SalaryDetails;
